"use client";

import styled, { useTheme } from "styled-components";
import Shimmer from "@common/components/Loaders/SkeletonLoader/Shimmer";
import { Flex } from "@common/styles/index";
const SkeletonLandingMob = () => {
  const theme = useTheme();
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="SkeletonLandingMob" data-sentry-source-file="SkeletonLandingMob.tsx">
			<Flex $alignItems="center" $justifyContent="center" data-sentry-element="Flex" data-sentry-source-file="SkeletonLandingMob.tsx">
				<Shimmer width="70%" height="40px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingMob.tsx" />
			</Flex>
			<Spacer margintop="32px" data-sentry-element="Spacer" data-sentry-source-file="SkeletonLandingMob.tsx" />
			<Flex $alignItems="center" $direction="column" $gap="8px" data-sentry-element="Flex" data-sentry-source-file="SkeletonLandingMob.tsx">
				<Shimmer width="100%" height="40px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingMob.tsx" />
				<Shimmer width="100%" height="40px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingMob.tsx" />
			</Flex>
			<Spacer margintop="1rem" data-sentry-element="Spacer" data-sentry-source-file="SkeletonLandingMob.tsx" />

			<Flex $alignItems="center" $direction="column" $gap="24px" data-sentry-element="Flex" data-sentry-source-file="SkeletonLandingMob.tsx">
				<Shimmer width="70%" height="40px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingMob.tsx" />
				<Shimmer width="70%" height="40px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingMob.tsx" />
			</Flex>
		</Wrapper>;
};
export default SkeletonLandingMob;
type SpacerTypes = {
  margintop: string;
};
const Wrapper = styled.div`
	height: 100vh;
	width: 100%;
	background: #0e0e0e;
	padding: 24px;
`;
export const Spacer = styled.div<SpacerTypes>`
	margin-top: ${({
  margintop
}) => margintop};
`;