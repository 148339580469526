import styled, { css, useTheme } from "styled-components";
import Shimmer from "@common/components/Loaders/SkeletonLoader/Shimmer";
import { Flex } from "@common/styles/index";
import { largeDesktopCss } from "@common/styles/media";
import { Spacer } from "./SkeletonLandingMob";
const SkeletonLandingDesk = () => {
  const theme = useTheme();
  return <Wrapper data-sentry-element="Wrapper" data-sentry-component="SkeletonLandingDesk" data-sentry-source-file="SkeletonLandingDesk.tsx">
            <Container $justifyContent="center" $direction="column" data-sentry-element="Container" data-sentry-source-file="SkeletonLandingDesk.tsx">
                <Shimmer width="80%" height="64px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingDesk.tsx" />
                <Spacer margintop="0.5rem" data-sentry-element="Spacer" data-sentry-source-file="SkeletonLandingDesk.tsx" />

                <Shimmer width="60%" height="64px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingDesk.tsx" />
                <Spacer margintop="1.5rem" data-sentry-element="Spacer" data-sentry-source-file="SkeletonLandingDesk.tsx" />
                <Shimmer width="40%" height="32px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingDesk.tsx" />

                <Spacer margintop="3.5rem" data-sentry-element="Spacer" data-sentry-source-file="SkeletonLandingDesk.tsx" />
                <Shimmer width="50%" height="64px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingDesk.tsx" />

                <Spacer margintop="0.5rem" data-sentry-element="Spacer" data-sentry-source-file="SkeletonLandingDesk.tsx" />
                <Shimmer width="30%" height="32px" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingDesk.tsx" />
				
			</Container>
            <Container $alignItems="center" $justifyContent="space-between" data-sentry-element="Container" data-sentry-source-file="SkeletonLandingDesk.tsx">
                <Shimmer width="500px" height="70%" bgColor={theme.colors.bg.shimmerInverse.level2} data-sentry-element="Shimmer" data-sentry-source-file="SkeletonLandingDesk.tsx" />
				
			</Container>
        </Wrapper>;
};
export default SkeletonLandingDesk;
const Wrapper = styled.div`
    display: flex;
	height: 100vh;
	width: 100%;
	background: #0e0e0e;
	padding: 45px 200px;
    justify-content: space-between;


	${largeDesktopCss(css`
		padding: 100px 200px 150px 200px;
		justify-content: space-between;
	`)}
`;
const Container = styled(Flex)`
    display: flex;
    flex-basis: 50% ;
`;