"use client";

import { useAppContext } from "@common/context/AppContext";
import SkeletonLandingDesk from "./SkeletonLandingDesk";
import SkeletonLandingMob from "./SkeletonLandingMob";
const SkeletonLoading = () => {
  const {
    isDesktop
  } = useAppContext();
  return isDesktop ? <SkeletonLandingDesk /> : <SkeletonLandingMob />;
};
export default SkeletonLoading;