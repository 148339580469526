import styled, { keyframes } from "styled-components";
type ShimmerProps = {
  bgColor?: string;
  width?: string;
  height?: string;
  margin?: string;
  borderRadius?: string;
};
const Shimmer: React.FC<ShimmerProps> = ({
  bgColor,
  width,
  height,
  margin,
  borderRadius = "4px"
}) => {
  return <Wrapper $bgColor={bgColor} $width={width} $height={height} $margin={margin} $borderRadius={borderRadius} data-sentry-element="Wrapper" data-sentry-component="Shimmer" data-sentry-source-file="Shimmer.tsx" />;
};
export default Shimmer;
const Pulsate = keyframes`
 0% {
    opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;
const Wrapper = styled.div<{
  $width?: string;
  $height?: string;
  $margin?: string;
  $bgColor?: string;
  $borderRadius?: string;
}>`
	background: ${({
  theme,
  $bgColor
}) => $bgColor || theme.colors.bg.shimmer.level1};
	width: ${({
  $width
}) => $width || ""};
	height: ${({
  $height
}) => $height || ""};
	margin: ${({
  $margin
}) => $margin || ""};
	border-radius: ${({
  $borderRadius
}) => $borderRadius || ""};
	animation: ${Pulsate} 1s ease-in;
	animation-iteration-count: infinite;
`;